<template>
  <div class="forgot-wrapper">
    <div class="container">
      <header>
        <div class="logo">
          <h1>
            <img src="../assets/nav/HectocommerceLogo.svg" />
          </h1>
          <div class="col-12 p-0 m-0 text-left back-btn">
            <span @click="goToPreviousPage">
                <img src="../assets/Login/rightArrow.svg"> BACK
            </span>
          </div>
        </div>
      </header>
    </div>
    <section>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <img
            class="forgot-img"
            src="../assets/forgotPassword/Forgot password-amico.svg"
          />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="content">
            <div class="main-content">
              <h1 class="forgot-title">Change Password</h1>
              <div class="forgot-form mt-5">
                <w-text-input
                  class="mt-4"
                  :labelName="'Enter your Current Password'"
                  :fieldType="'password'"
                  :disabled="false"
                  :defaultValue="oldPassword"
                  :validation="''"
                  :newInputStyle="'loginInput'"
                  :placeholder="'Enter your Current Password'"
                  :fieldName="'Email Address'"
                  :labelStyle="'login-input-label'"
                  @textInputChange="getOldPassword($event)"
                />
                <w-text-input
                  class="mt-4"
                  :labelName="'Enter Your New Password'"
                  :fieldType="'password'"
                  :disabled="false"
                  :defaultValue="newPassword"
                  :validation="''"
                  :newInputStyle="'loginInput'"
                  :placeholder="'Enter Your New Password'"
                  :fieldName="'Email Address'"
                  :labelStyle="'login-input-label'"
                  @textInputChange="getNewPassword($event)"
                />
                <w-text-input
                  class="mt-4"
                  :labelName="'Re-enter Your New Password'"
                  :fieldType="'password'"
                  :disabled="false"
                  :defaultValue="rePassword"
                  :validation="''"
                  :newInputStyle="'loginInput'"
                  :placeholder="'Re-enter Your New Password'"
                  :fieldName="'Email Address'"
                  :labelStyle="'login-input-label'"
                  @textInputChange="getRePassword($event)"
                />
                <span style="font-size:12px; color:red" v-if="isErrorMsg"
                  >Password doesn't match</span
                >
                <div class="reset-btn text-center mt-3">
                  <w-button-input
                    :buttonClass="'auth-button'"
                    :buttonStyle="'auth-button-style'"
                    @buttonClicked="resetPasswordButton()"
                    :isDisabled="isResetButton"
                    :label="'RESET PASSWORD'"
                  />
                </div>
              </div>
            </div>
            
          </div>
          <div class="copy-right">
            <p>
              Copyright © 2021 HectoCommerce, A DIATOZ Product, All rights
              reserved
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- error modal -->
    <Modal
      :trigger="alert"
      :title="'D-Ecommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
    />
  </div>
</template>

<script>
import TextInput from "../widgets/TextInput.vue";
import ButtonInput from "../widgets/InputButton.vue";
import { changePassword } from "../Service/LoginService.js";
import Modal from "../widgets/ModalWidget";

export default {
  components: {
    "w-text-input": TextInput,
    "w-button-input": ButtonInput,
    Modal,
  },
  data() {
    return {
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,

      oldPassword: "",
      newPassword: "",
      rePassword: "",
      isErrorMsg: false,
    };
  },

  computed: {
    isResetButton() {
      if (
        !this.oldPassword == "" &&
        !this.newPassword == "" &&
        !this.rePassword == ""
      ) {
        if (this.newPassword != this.rePassword) {
          this.isErrorMsg = true;
          return true;
        } else {
          this.isErrorMsg = false;
          return false;
        }
      } else {
        return true;
      }
    },
  },

  methods: {
    getOldPassword(event) {
      this.oldPassword = event;
    },

    getNewPassword(event) {
      this.newPassword = event;
    },

    getRePassword(event) {
      this.rePassword = event;
    },

    resetPasswordButton() {
      let data = {
        currentPassword: this.oldPassword,
        newPassword: this.newPassword,
      };
      changePassword(data)
        .then((res) => {
            this.alert = true;
            this.isConfirmAlert = false;
            this.alertMsg = "Password has been changed. Please login";
            this.alertType = "Success";
            setTimeout(() => {
              this.goToLoginAfterReset();
            }, 1000);
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.title;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    goToLoginAfterReset(){
        sessionStorage.clear();
        localStorage.clear();
        this.$router.push("/login")
    },

    goToPreviousPage(){
        window.history.back();
    },

    // close modal
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
  },
};
</script>

<style scoped>
.forgot-wrapper {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #fff;
}
section {
  background: url("../assets/forgotPassword/Intersection 3.png") no-repeat;
  background-size: 50% 100%;
  background-position: left;
  height: 100vh;
}
.forgot-wrapper::-webkit-scrollbar {
  display: none;
}
.logo {
  font-family: "bebas-kai";
  font-size: 2rem;
  color: #464646;
  /* cursor: pointer; */
}
.forgot-img {
  position: fixed;
  top: 15%;
  left: 15%;
}
.content {
  margin: 8% 15% 0;
}
.forgot-title {
  font: normal normal normal 40px/40px "bebas-kai";
  color: #464646;
  text-align: center;
}
.content h3 {
  font: normal normal normal 30px/40px "bebas-kai";
  color: #464646;
  text-align: center;
}
.forgot-form h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 2rem;
}
p {
  font: normal normal normal 10px/12px Quasimoda;
  color: #0475a1;
  text-transform: uppercase;
  position: fixed;
  bottom: 0;
  right: 130px;
}
.back-btn{
    font-size: 18px;
    font-family: "bebas-kai";
    color: #A3A3A3;
}
.back-btn span{
    cursor: pointer;
}
@media screen and (min-width: 768px) and (max-width: 991px){
  .forgot-img{
    left: 7%;
    width: 35%;
  }
  section{
    background-size: 40% 75%;
  }
}
@media screen and (min-width: 575px) and (max-width: 768px){
  .forgot-img{
    top: 64%;
    width: 180px;
  }
}

@media screen and (max-width: 574.99px){
  .forgot-img{
    width: 150px;
    top: 72%;
  }
}
@media screen and (max-width: 767.99px) {
  .forgot-title {
    font: normal normal normal 20px/20px "bebas-kai";
  }
  .content {
    margin: 0 5% 0;
  }
  .content h3 {
    font-size: 14px;
  }
  section {
    background-position: left bottom;
    background-size: 60% 40%;
  }
  /* .forgot-img {
    width: 45%;
    top: 60%;
    bottom: 10%;
    left: 20%;
  } */
  .forgot-form {
    margin-top: 10px !important;
  }
  .forgot-form h2 {
    font-size: 16px;
    margin-bottom: 1rem;
  }
  .reset-btn {
    margin-top: 20px !important;
  }
  p {
    display: none;
  }
}
</style>
